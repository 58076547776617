.maintenance-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0;
    text-align: center;
}

.maintenance-content {
    max-width: 500px;
    padding: 20px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.maintenance-icon {
    width: 100px;
    margin-bottom: 20px;
}

h1 {
    font-size: 2rem;
    margin-bottom: 10px;
}

p {
    font-size: 1rem;
    color: #555;
}
